
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../utils/auth";
import {
  IconHome,
  IconList,
  IconSettings,
  IconUser,
  IconLogout,
  IconUsers,
  IconSquareRoundedChevronsLeft,
  IconSquareRoundedChevronsRight,
  IconBrandGoogle
} from "@tabler/icons-react";

const Sidebar = ({ logout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(() => {
  const saved = localStorage.getItem("sidebarCollapsed");
  return saved === "true";
});
  const [user, setUser] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 993);

  useEffect(() => {
  localStorage.setItem("sidebarCollapsed", isCollapsed);

    const fetchUser = async () => {
      const userData = await getUser();
      setUser(userData);
    };

    const handleResize = () => {
      const desktop = window.innerWidth > 993;
      setIsDesktop(desktop);
      if (!desktop) setIsCollapsed(false);
    };

    window.addEventListener("resize", handleResize);
    fetchUser();
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const iconSize = 24;
  const displayName = user?.firstname || "User";

  return (
    <aside className={`navbar navbar-vertical navbar-expand-lg${isCollapsed ? " sidebar-collapsed" : ""}`} data-bs-theme="dark">
      <div className="container-fluid" style={{ fontSize: "16px" }}>
        <button
          className={`navbar-toggler ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {!isCollapsed && (
          <div className="navbar-brand navbar-brand-autodark text-center d-block">
            <a href="/">
              <img src="/images/logo.webp" alt="IndexBooster" className="navbar-brand-image" width="110" height="32" />
            </a>
          </div>
        )}

        <div className={`navbar-collapse collapse ${isOpen ? "show" : ""}`} id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            {!isCollapsed && (
              <>
                <li className="nav-item">
                  <div className="nav-link text-muted">Hy, {displayName}!</div>
                </li>
                <li className="nav-item">
                  <div className="nav-link text-white fw-bold">Credits: {user?.credits ?? 0}</div>
                </li>
              </>
            )}
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconHome size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Dashboard</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/indexing" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconList size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Indexing Control</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/logs" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconBrandGoogle size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Googlebot Logs</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/settings" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconSettings size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Settings</span>}
              </Link>
            </li>
            {user?.role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link" to="/users" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                  <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconUsers size={iconSize} /></span>
                  {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>All Users</span>}
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link className="nav-link" to="/profile" onClick={() => window.innerWidth < 993 && setIsOpen(false)}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconUser size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Edit Profile</span>}
              </Link>
            </li>
            <li className="nav-item">
              <button className="nav-link text-danger w-100 text-start" onClick={logout}>
                <span className="nav-link-icon" style={{ height: "1.6rem" }}><IconLogout size={iconSize} /></span>
                {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Logout</span>}
              </button>
            </li>
            {isDesktop && (
              <li className="nav-item mt-3">
                <button
                  className="nav-link w-100 text-start text-secondary"
                  onClick={() => { setIsCollapsed(!isCollapsed); setIsOpen(false); }}
                >
                  <span className="nav-link-icon" style={{ height: "1.6rem" }}>
                    {isCollapsed ? <IconSquareRoundedChevronsRight size={iconSize} /> : <IconSquareRoundedChevronsLeft size={iconSize} />}
                  </span>
                  {!isCollapsed && <span className="nav-link-title" style={{ marginLeft: "0.5rem" }}>Collapse Menu</span>}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
